<template>
  <div class="list-item-wrapper" @click="goToGarden(garden)">
    <div class="garden-row">
      <div class="garden-photo">
        <div class="garden-icon-info">
          <div class="is-garden d-block d-xl-none">
            <span>{{ isParentGarden ? 'Group of gardens' : 'Garden'}}</span>
          </div>
          <div class="tree-icon">
            <ngs-icon
              v-if="treeIcon"
              :tag="treeIcon"
              :showTooltip="false"/>
          </div>
        </div>
        <b-card-img-lazy
          v-if="garden.featured_image"
          :src="garden.featured_image"
          :alt="`${garden.name} - image 1`"/>
        <img-placeholder v-else/>
      </div>
      <div class="garden-info">
        <div class="info-header">
          <div class="info-header-top">
            <div class="view-type" :class="viewingType(true)">
              {{ viewingType() }}
            </div>
            <div class="new-tag" v-if="isNewGarden">
              <div class="badge rounded-pill bg-info">New</div>
            </div>
          </div>
          <div class="garden-name"><h3 class="m-0">{{ garden.name }}</h3></div>
          <div class="garden-address">
            {{ garden.address_2 ? `${garden.address_2}, ` : null }}
            {{ garden.address_3 ? `${garden.address_3}, ` : null }}
            {{ garden.town ? `${garden.town}, ` : null }}
            {{ garden.county ? `${garden.county}, ` : null }}
            {{ garden.postcode ? garden.postcode : null }}
          </div>
      </div>
        <div class="garden-tags">
          <ngs-icon-group :tags="garden.tags"/>
          <div class="details-button-wrapper d-flex d-xl-none">
            <b-button
              class="details-button ngs-font"
              variant="primary"
              size="lg"
            >
              Details
            </b-button>
          </div>
        </div>
        <div class="info-footer" v-if="nextOpenings.length">
          <div class="openings-wrapper">
            <div class="openings-title">Next openings</div>
            <div class="openings-time-slots-wrapper">
              <div v-for="(opening, openingIndex) in nextOpenings"
                :key="openingIndex"
              >
                <b-button
                  class="opening-time-slot-button"
                  variant="outline-secondary"
                  size="sm">
                  {{ opening.start_date | moment("D MMM YYYY") }}
                </b-button>
                <span
                  v-if="opening.canceled"
                  class="cancelled-label ml-1"
                >
                  Cancelled
                </span>
                <b-button
                  v-if="!opening.canceled && (opening.prebookingthroughgarden || opening.prebookingthroughngs)"
                  size="sm"
                  class="ml-1 prebooking-button"
                >
                  Pre-booking Essential
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  find,
  filter,
  includes,
  kebabCase,
  take,
} from 'lodash';
import { mapGetters } from 'vuex';
import NgsIconGroup from '@/components/ngsIcons/NgsIconGroup';
import NgsIcon from '@/components/ngsIcons/NgsIcon';
import ImgPlaceholder from '@/components/images/imgPlaceholder';
import { GARDEN_OPENING_TYPE_IDS_OPENING, GARDEN_OPENING_TYPE_IDS_ARRANGEMENT } from '@/constants'

export default {
  name: 'ListItem',

  components: {
    ImgPlaceholder,
    NgsIconGroup,
    NgsIcon,
  },

  props: {
    garden: Object,
  },

  computed: {
    ...mapGetters('ngsicons', [
      'findIconByTag',
    ]),
    treeIcon() {
      return this.isParentGarden ? this.findIconByTag('flowers') : this.findIconByTag('plants');
    },
    isParentGarden() {
      return this.garden.garden_type_id === 2;
    },
    isNewGarden() {
      return find(this.garden.tags, ['name', 'new-garden']) ?? false;
    },
    hasPreBookings() {
      return filter(this.garden.openings, 'prebookingavailable').length > 0;
    },

    hasOpenings() {
      return filter(this.garden.openings, function (opening) {
        return includes(GARDEN_OPENING_TYPE_IDS_OPENING, opening.garden_opening_type_id)
      }).length > 0;
    },

    hasArrangement() {
      return filter(this.garden.openings, function (opening) {
        return includes(GARDEN_OPENING_TYPE_IDS_ARRANGEMENT, opening.garden_opening_type_id)
      }).length > 0;
    },

    hasPreBookingsAndArrangement() {
      return ((this.garden.preBookings || this.hasPreBookings) && this.hasArrangement) ?? false;
    },

    hasOpeningsAndArrangement() {
      return (this.hasOpenings && this.hasArrangement) ?? false;
    },
    nextOpenings() {
      const now = new Date().setHours(0, 0, 0, 0);
      const openings = filter(this.garden.openings, (opening) => {
        // Safari does not support yyy-MM-dd date format... sigh...
        let startDate = opening.start_date.replace(/-/g, '/');
        startDate = new Date(startDate).setHours(0, 0, 0, 0);

        return startDate >= now && !this.isByArrangementOpening(opening)
      })

      return take(openings, 2)
    },
  },

  methods: {
    isByArrangementOpening(opening) {
      return includes(GARDEN_OPENING_TYPE_IDS_ARRANGEMENT, opening.garden_opening_type_id)
    },
    goToGarden(garden) {
      this.$router.push({ name: 'Garden', params: { gardenId: garden.id, gardenName: kebabCase(garden.name) } })
    },
    viewingType(returnClass = false) {
      if (this.hasOpeningsAndArrangement) {
        return returnClass
          ? 'openings-and-arrangement'
          : 'Openings & Arrangement';
      }

      if (this.hasOpenings) {
        return returnClass
          ? 'openings-only'
          : 'Openings';
      }

      if (this.hasArrangement) {
        return returnClass
          ? 'arrangement-only'
          : 'By Arrangement';
      }

      return '';
    },
  },
}
</script>

<style lang="scss">
#listings-wrapper {
  .list-item-wrapper {
    padding: 0 17px;
    cursor: pointer;

    &:hover {
      background-color: $color-yellow-lighter;
    }

    .garden-row {
      padding: 0 0 20px 0;
      @include media-breakpoint-up(sm) {
        padding-top: 20px;
      }
      border-bottom: 1px solid #E2E2E2;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: nowrap;

      .garden-photo {
        position: relative;
        width: 243px;

        .garden-icon-info {
          position: absolute;
          right: 0;
          display: flex;
          align-items: center;

          .is-garden {
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
          }

          .tree-icon {
            width: 28px;
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 100%;
            margin: 13px;
            border: 1px solid $color-yellow-light;
          }
        }

        .b-aspect-sizer {
          height: 171px !important;
        }

        img {
          height: 171px;
          width: 243px;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }

      .garden-info {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        padding: 0 20px;

        .view-type {
          font-size: 12px;
          line-height: 150.02%;
          letter-spacing: 0.2px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 5px;

          &.openings-and-arrangement {
            color: #008C4A;
          }

          &.openings-only {
            color: $primary;
          }

          &.arrangement-only {
            color: #C8441B;
          }
        }

        .garden-address {
          font-family: "NGS 2017";
          font-size: 24px;
          color: $color-grey-main;
        }

        .info-footer {
          .openings-wrapper {
            .openings-title {
              font-size: 14px;
            }

            .openings-time-slots-wrapper {
              display: flex;
              flex-direction: column;
              align-items: flex-start;

              > div {
                margin-top: 5px;

                button {
                  margin-right: 5px;
                  min-width: 106px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  #listings-wrapper {
    .list-item-wrapper {
      padding: 0;

      .garden-row {
        flex-direction: column;
        align-items: center;

        .garden-photo {
          width: 100%;

          img {
            border-radius: 0;
            height: 190px;
            width: 100%;
          }
        }

        .garden-info {
          width: 100%;
          padding: 1rem 1rem 0;

          .garden-tags {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
          }
        }
      }
    }
  }
}
</style>
