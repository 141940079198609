<template>
  <div id="list-header-wrapper">
    <div class="filter-info-wrapper">
      <div class="filter-info">
        <div class="active-filters-count">
          <span class="filter-count">{{ selectedFilterOptionsCount }}</span>
          Active filters
        </div>
        <font-awesome-icon class="separator-icon" :icon="['fas', 'circle']"/>
        <b-button
          :disabled="!selectedFilterOptionsCount"
          variant="link"
          @click="resetFiltersAndGardens()">Reset all</b-button>
      </div>
      <div class="results-count-sort-wrapper">
        <div class="results-count-wrapper">
          <div class="results-count">
            <span class="filter-count">{{ gardens.length }}</span>
            results
          </div>
        </div>
        <SortButton class="sort-button-component"/>
      </div>
    </div>
    <div class="helper-text">
      <div v-if="dateRangeFilterUsed" class="date-range-notice">
        These gardens open during your specified date range, please select each garden to see all available dates.
      </div>
      <div v-if="isOpeningFilterUsed">
        These are regular garden openings. For By Arrangement openings, click on 'By Arrangement' on the filters above.
      </div>
      <div v-else>
        These are by arrangement garden openings. For regular openings, click on 'Opening' on the filters above.
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SortButton from '@/components/gardenList/SortButton'

export default {
  name: 'ListHeader',

  components: {
    SortButton,
  },

  computed: {
    ...mapGetters('garden', [
      'gardens',
    ]),

    ...mapGetters('filter', [
      'selectedFilterOptionsCount',
      'sectionFilterSelected',
      'selectedFilters',
    ]),

    dateRangeFilterUsed() {
      return this.sectionFilterSelected('date_open_from')?.length && this.sectionFilterSelected('date_open_to')?.length ? true : false;
    },

    isOpeningFilterUsed() {
      const selectedFilter = this.selectedFilters.filter((filter) => filter.filter === 'opening_type')[0];

      return selectedFilter.options === 'opening';
    },
  },

  methods: {
    ...mapActions('garden', [
      'searchGardens',
    ]),
    ...mapActions('filter', [
      'resetFiltersAndGardens',
    ]),
  },
}
</script>

<style scoped lang="scss">
#list-header-wrapper {
  border-bottom: 1px solid $color-grey-light;
  color: $color-grey-main;
  align-items: center;
  margin: 0.75rem 1rem 0 1rem;
  padding-bottom: 0.75rem;
  font-size: 0.75rem;

  .filter-info {
    display: flex;
    flex-grow: 1;
    align-items: center;
    gap: 0.5rem;
  }

  .filter-info-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .helper-text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin: 0;
  }

  .active-filters-count {
    display: flex;
    gap: 0.25rem;
    white-space: nowrap;
    .filter-count {}
  }
  .separator-icon {
    font-size: 0.2rem;
  }
  .btn {
    font-size: 0.75rem;

    &.btn-link {
      text-decoration: none;
      padding: 0;
      &:focus{
        box-shadow: none;
      }
      &:active{
        box-shadow: 0 0 0 0.2rem rgba($color-yellow-main, 0.25);
      }
    }
  }
  .results-count-sort-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>
